import React, { memo, useEffect, useRef, useState } from "react";
import { Box, Container, Hidden, Typography } from "@mui/material";
import styles from "./style.module.scss";
import Link from "components/Link";

import theme from "theme";
import { AddBoxSharp, ArrowBackIosNew, Twitter } from "@mui/icons-material";
import { FacebookIcon, PhoneIcon } from "assets/svg";
const FooterListElement = ({ text, link }) => {
  return (
    <li>
      <Link href={link}>
        <Typography component="span">{text}</Typography>
      </Link>
    </li>
  );
};

const FooterListComponent = ({ items, title = "" }) => {
  const [open, setOpen] = useState(false);
  const [size, setSize] = useState(null);
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      setSize(ref.current.offsetHeight);
    }
  }, [ref]);

  return (
    <Box
      onClick={() => setOpen((prev) => !prev)}
      alignSelf="self-start"
      sx={{
        "@media (max-width: 960px)": {
          alignSelf: "auto",
        },
      }}
    >
      <Box
        display="flex"
        sx={{
          "@media (max-width: 960px)": {
            marginBottom: "6px",
            flexDirection: "row !important",
            justifyContent: "space-between !important",
            borderBottom: "none !important",
          },
        }}
      >
        <Typography
          variant="h4"
          textTransform="uppercase"
          sx={{
            marginBottom: "10px",
            "@media (max-width: 960px)": {
              marginBottom: "6px",
              width: "100%",
            },
          }}
        >
          {title}
        </Typography>
        <Hidden mdUp>
          <Box
            width="30px"
            height="30px"
            sx={{
              transform: `rotate(${open ? 90 : -90}deg)`,
              transition: "0.3s",
              transformOrigin: "center",

              flexDirection: "row !important",
              justifyContent: "space-between !important",
              borderBottom: "none !important",
            }}
          >
            <Box
              component="span"
              mt="0 !important"
              width="30px"
              height="30px"
              sx={{
                display: "flex",
                justifyContent: "center !important",
                alignItems: "center !important",
              }}
            >
              <ArrowBackIosNew />
            </Box>
          </Box>
        </Hidden>
      </Box>
      <Box
        ref={ref}
        component="ul"
        padding="0"
        sx={{
          "@media (max-width: 960px)": {
            transition: "0.3s",
            height: size ? (open ? `${size}px` : 0) : "auto",
            transform: open ? `scaleY(1)` : `scaleY(0)`,
            transformOrigin: "top",
            my: 0,
            mb: 2,
          },
        }}
      >
        {items?.map(({ label, link }) => (
          <FooterListElement key={label} text={label} link={link} />
        ))}
      </Box>
    </Box>
  );
};

const Footer = memo(({ small = false }) => {
  const currentYear = new Date().getFullYear();

  return (
    <footer
      style={{
        paddingTop: small ? 0 : 10,
        marginTop: small ? 0 : 10,
        borderTop: `1px solid ${!small ? "#e8e8e8" : "transparent"}`,
      }}
    >
      {!small && (
        <Box
          className={styles.mainFooter}
          style={{
            maxWidth: theme.breakpoints.values.lg,
            margin: "10px auto 0",
          }}
        >
          <Container className={styles.container}>
            <nav>
              <FooterListComponent
                title="EXPANDER"
                items={[
                  {
                    label: "O firmie",
                    link: "https://www.expander.pl/o-firmie/",
                  },
                  {
                    label: "Kariera",
                    link: "https://www.expander.pl/kariera-i-wspolpraca/",
                  },
                  {
                    label: "Kontakt z Expanderem",
                    link: "https://www.expander.pl/kontakt/",
                  },
                  {
                    label: "Kontakt dla mediów",
                    link: "https://www.expander.pl/kontakt-do-mediow/",
                  },
                  {
                    label: "English summary",
                    link: "https://www.expander.pl/english-summary/",
                  },
                ]}
              />
              <FooterListComponent
                title="Oferta"
                items={[
                  {
                    label: "Kredyty",
                    link: "https://www.expander.pl/kredyty/",
                  },
                  {
                    label: "Inwestycje",
                    link: "https://www.expander.pl/inwestycje/",
                  },
                  {
                    label: "Ubezpieczenie",
                    link: "https://www.expander.pl/ubezpieczenia/",
                  },
                  {
                    label: "Oferta dla firm",
                    link: "https://www.expander.pl/oferta-dla-firm/",
                  },
                  {
                    label: "Nieruchomości",
                    link: "https://www.expander.pl/nieruchomosci/",
                  },
                  {
                    label: "Partnerzy",
                    link: "https://www.expander.pl/partnerzy/",
                  },
                ]}
              />
              <FooterListComponent
                title="Kalkulatory"
                items={[
                  {
                    label: "Kredytowe",
                    link: "https://www.expander.pl/kalkulatory/kredytowe/",
                  },
                  {
                    label: "Inwestycyjne",
                    link: "https://www.expander.pl/kalkulatory/inwestycyjne",
                  },
                  {
                    label: "Emerytalne",
                    link: "https://www.expander.pl/kalkulatory/emerytalne/",
                  },
                  {
                    label: "Ubezpieczeniowe",
                    link: "https://www.expander.pl/kalkulatory/ubezpieczeniowe/",
                  },
                ]}
              />
              <FooterListComponent
                title="Przydatne"
                items={[
                  {
                    label: "Regulaminy promocji",
                    link: "http://expander.pl/noty-prawne/#regulaminy",
                  },
                  {
                    label: "Regulamin serwisu",
                    link: "https://expander.pl/noty-prawne/#regulamin-serwisu",
                  },
                  {
                    label: "Polityka prywatności",
                    link: "https://www.expander.pl/polityka-prywatnosci",
                  },
                  {
                    label: "Mapa serwisu",
                    link: "https://www.expander.pl/mapa-strony/",
                  },
                  {
                    label: "Reklamacje",
                    link: "https://www.expander.pl/reklamacje/",
                  },
                  {
                    label: "FAQ",
                    link: "https://www.expander.pl/pytania-i-odpowiedzi/",
                  },
                ]}
              />

              <Box
                className={styles.helpline}
                sx={{
                  [theme.breakpoints.down("md")]: {
                    borderBottom: "0 !important",
                  },
                }}
              >
                <Typography
                  variant="h4"
                  textTransform="uppercase"
                  style={{ marginBottom: "10px" }}
                >
                  Infolinia
                </Typography>

                <Box
                  padding="0"
                  sx={{
                    [theme.breakpoints.down("md")]: {
                      marginBottom: "0 !important",
                    },
                  }}
                >
                  <Box
                    alignItems="center"
                    sx={{ flexDirection: "row !important" }}
                  >
                    <PhoneIcon />

                    <Box className={styles.footerNumbers} pl="10px !important">
                      <Typography
                        fontWeight={800}
                        color="primary"
                        fontSize="1.5rem"
                      >
                        <a href="tel:801670000"> 801 67 00 00</a>
                      </Typography>
                      <Typography
                        fontWeight={800}
                        color="primary"
                        fontSize="1.5rem"
                      >
                        <a href="tel:225632555"> 22 563 25 55</a>
                      </Typography>
                    </Box>
                  </Box>
                  <Typography fontSize="0.75rem" color="grey.400">
                    Infolinia (koszt za każdą rozpoczętą minutę wg stawek
                    operatora
                  </Typography>
                  <Box
                    mt={4}
                    sx={{
                      [theme.breakpoints.down("md")]: {
                        mb: "0 !important",
                      },
                    }}
                  >
                    <Typography variant="h4" textTransform="uppercase">
                      Dołącz do nas
                    </Typography>
                    <Box mt={2} sx={{ display: "inline-block !important" }}>
                      <a
                        href="https://www.facebook.com/ExpanderPolska/"
                        target="_blank"
                        rel="noreferrer"
                        style={{ width: "max-content" }}
                        className="trasition"
                        aria-label="facebook link"
                      >
                        <FacebookIcon size={36} />
                      </a>
                      <a
                        href="https://twitter.com/_JSadowski_"
                        target="_blank"
                        rel="noreferrer"
                        style={{ width: "max-content" }}
                        className="trasition"
                        aria-label="twitter link"
                      >
                        <Twitter
                          sx={{
                            ml: 2,
                            fontSize: 36,
                            cursor: "pointer",
                            ":hover": {
                              color: "black",
                            },
                          }}
                        />
                      </a>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </nav>
          </Container>
        </Box>
      )}
      <Box paddingY={"16px !important"} borderTop={1} borderColor={"#e8e8e8"}>
        <Box
          sx={{
            maxWidth: theme.breakpoints.values.lg,
            margin: "0 auto  ",
            [theme.breakpoints.down("md")]: {
              mx: 4,
            },
          }}
        >
          <Typography
            component="span"
            fontSize="0.85rem"
            color="grey.600"
            sx={{
              [theme.breakpoints.down("md")]: {
                fontSize: "0.65rem",
              },
            }}
          >
            {`© ${currentYear} Expander Nieruchomości. Wszystkie prawa zastrzeżone. Korzystanie z serwisu oznacza akceptację regulaminu.`}
          </Typography>
        </Box>
      </Box>
    </footer>
  );
});
Footer.displayName = "Footer";

export default Footer;
